import React from 'react'
import { graphql, navigate } from 'gatsby'
import '../css/completion.scss'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import getPlanData from '../components/util/getPlanData'
import withUser from '../components/with-user/with-user.component'
import { Button } from 'antd'
import { mutate } from 'swr'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const Completion = ({ data, location }) => {
	const { bg, img, name: title } = getPlanData({ nickname: location.state.name })

	const getBg = () => ({ background: `url(${bg}) no-repeat center / cover` })

	const getLayer = () => ({ background: `url(${img}) no-repeat center / cover` })

	React.useEffect(() => {
		mutate('plans')
	}, [])

	return (
		<Layout>
			<SEO title="Completion" />
			<div className="completion-page" style={getLayer()}>
				<div className="Header-parent">
					<MDXRenderer>{data.Header.nodes[0].body}</MDXRenderer>
				</div>
				<div className="page-content" style={{ ...getBg(), minHeight: '100vh' }}>
					<div className="steps-parent">
						<MDXRenderer>{data.Steps.nodes[0].body}</MDXRenderer>
					</div>
					<div className="title-parent">
						<h2>{title}</h2>
					</div>
					<div className="completion-parent">
						<MDXRenderer>{data.Completion.nodes[0].body}</MDXRenderer>
						<div className="app-url">
							<OutboundLink href={location.state.app_url}>
								<Button
									type="primary"
									size="large"
									shape="round"
									className="app-btn"
								>
									Start Exploring
								</Button>
							</OutboundLink>
						</div>
						<div style={{ height: '60px' }}></div>
					</div>
				</div>
			</div>
		</Layout>
	)
}
export const pageQuery = graphql`
	query CompletionQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Steps: allMdx(filter: { frontmatter: { title: { eq: "Steps" } } }) {
			nodes {
				body
			}
		}
		Completion: allMdx(filter: { frontmatter: { title: { eq: "Completion" } } }) {
			nodes {
				body
			}
		}
	}
`

function HOC(Page) {
	return function (props) {
		if (!props.location || !props.location.state) {
			navigate('/')
			return <div>redirecting...</div>
		}

		return <Page {...props} />
	}
}

export default withUser(HOC(Completion))
