import customerCover from "../../images/customer-cover.svg";
import operationsCover from "../../images/operations-cover.svg";
import employeeCover from "../../images/employee-cover.svg";
import actionableCover from "../../images/actionable-cover.svg";
import customer from "../../images/customer.svg";
import operations from "../../images/operations.svg";
import employee from "../../images/employee.svg";
import actionable from "../../images/actionable.svg";

const data = {
    "customer engagement": {
        minUsers: 100,
        name: "Customer Engagement",
        type: "Starter",
        bg: customerCover,
        img: customer
    },
    "customer engagment": {
        minUsers: 100,
        name: "Customer Engagement",
        type: "Starter",
        bg: customerCover,
        img: customer
    },
    "employee productivity": {
        minUsers: 100,
        name: "Employee Productivity",
        type: "Starter",
        bg: employeeCover,
        img: employee
    },
    "operation excellence": {
        minUsers: 1,
        name: "Operation Excellence",
        type: "Starter",
        bg: operationsCover,
        img: operations
    },
    "actionable insight": {
        minUsers: 5,
        name: "Actionable Insight",
        type: "Starter",
        bg: actionableCover,
        img: actionable
    },
    "market place": {
        minUsers: 1,
        name: "Market Place",
        type: "Starter",
    }
}


function getPlanData(plan){
    return data[plan.nickname.toLowerCase()] || {};
}

export default getPlanData;